export function formatDate(date, time) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
export function formatTime(date) {
    var d = new Date(date),
        hours = '' + (d.getHours()),
        minutes = '' + d.getMinutes();

    if (hours.length < 2)
        hours = '0' + hours;
    if (minutes.length < 2)
        minutes = '0' + minutes;

    return [hours, minutes].join(':');
}

export function formatDateTime(date, time) {
    return formatDate(date) + " " + formatTime(time);
}

export function monthName(month) {
    if(month === '1' ||month === 1 || month === 'Jan') {
        return 'Enero'
    }
    if(month === '2' ||month === 2 || month === 'Jan') {
        return 'Febrero'
    }
    if(month === '3' ||month === 3 || month === 'Jan') {
        return 'Marzo'
    }
    if(month === '4' ||month === 4 || month === 'Jan') {
        return 'Abril'
    }
    if(month === '5' ||month === 5 || month === 'Jan') {
        return 'Mayo'
    }
    if(month === '6' ||month === 6 || month === 'Jan') {
        return 'Junio'
    }
    if(month === '7' ||month === 7 || month === 'Jan') {
        return 'Julio'
    }
    if(month === '8' ||month === 8 || month === 'Jan') {
        return 'Agosto'
    }
    if(month === '9' ||month === 9 || month === 'Jan') {
        return 'Septiembre'
    }
    if(month === '10' ||month === 10 || month === 'Jan') {
        return 'Octubre'
    }
    if(month === '11' ||month === 11 || month === 'Jan') {
        return 'Noviembre'
    }
    if(month === '12' ||month === 12 || month === 'Jan') {
        return 'Diciembre'
    }
}