import React, { useEffect } from 'react'
import { useState } from "react";

// import TodoList from '../../../components/TodoList'
// import { getTareas } from '../../../services/tarea'
import { getProyectosActivos } from '../../../services/proyecto';
import { getPublicidades } from '../../../services/publicidad';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Grid, Typography } from '@material-ui/core';

import noResult from '../../../assets/img/no-result.png'
import LoadingComponent from '../../../components/Loading';

import { PRIMARY_COLOR, ERROR_COLOR } from '../../../constants/colores'

import { useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Moment from "react-moment"
import EditIcon from '@material-ui/icons/Edit';

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";
import { display } from '@mui/system';
import { BorderBottom } from '@material-ui/icons';
import { monthName } from '../../../utils/dateTime';
const useStyles = makeStyles((theme) => ({
    containerMain: {
        marginTop: '80px',
        display: 'flex',
        justifyContent: 'center',
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    cardProyecto: {
        // maxWidth: 345,
    },
    cardProyectoSeleccionado: {
        borderBottom: '6px solid ' + PRIMARY_COLOR,
        borderBottomWidth: '6px'
        // maxWidth: 345,
    },
    cardMedia: {
        height: 140,
    },
    contendorMarcadosProyectos: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
    },
    marcadorProyectos: {
        fontSize: '72px',
        fontWeight: 'bolder',
        color: '#d0d0d0',
        lineHeight: 1
    }
}));


export default function HomeContent() {
    const classes = useStyles();

    const [proyectos, setProyectos] = useState([]);
    const [publicidades, setPublicidades] = useState([]);
    const [proyectoSeleccionado, setProyectoSeleccionado] = useState('1')
    const [mesSeleccionado, setMesSeleccionado] = useState( new Date().getMonth()+1 )
    const [yearSeleccionado, setYearSeleccionado] = useState( new Date().getFullYear() )

    const [loading, setLoading] = useState(false)

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {
        setLoading(true)

        const usuario_id = usuarioSeleccionado.id

        const str_mesSeleccionado = ( mesSeleccionado < 10 ? `0${mesSeleccionado}` : mesSeleccionado )
        const ultimoDia = new Date(new Date().getFullYear(), mesSeleccionado, 0).getDate()
        const fecha_desde = `${yearSeleccionado}-${str_mesSeleccionado}-01 00:00:00`
        const fecha_hasta = `${yearSeleccionado}-${str_mesSeleccionado}-${ultimoDia} 00:00:00`

        getProyectosActivos({ usuario_id, fecha_desde, fecha_hasta }).then((response) => {

            response && setProyectos(response.proyectos)
            console.log(response);
            setLoading(false)

        });

    }, [])

    useEffect(() => {

        const usuario_id = usuarioSeleccionado.id

        const str_mesSeleccionado = ( mesSeleccionado < 10 ? `0${mesSeleccionado}` : mesSeleccionado )
        const ultimoDia = new Date(new Date().getFullYear(), mesSeleccionado, 0).getDate()
        const fecha_desde = `${yearSeleccionado}-${str_mesSeleccionado}-01 00:00:00`
        const fecha_hasta = `${yearSeleccionado}-${str_mesSeleccionado}-${ultimoDia} 00:00:00`

        getPublicidades({ 
            usuario_id, 
            proyecto_id: proyectoSeleccionado, 
            fecha_desde: fecha_desde, 
            fecha_hasta: fecha_hasta 
        }).then((response) => {

            response && setPublicidades(response.publicidades)
            response && console.log(response.publicidades)

        });

        getProyectosActivos({ usuario_id, fecha_desde, fecha_hasta }).then((response) => {

            response && setProyectos(response.proyectos)
            console.log(response);
            setLoading(false)

        });
    }, [proyectoSeleccionado, mesSeleccionado, yearSeleccionado])

    const selectProyectoClick = (id) => {
        setProyectoSeleccionado(id)
    }

    const BotonesSeleccionProyecto = () => {
        return (

            <Grid container spacing={3}>
                {
                    proyectos.map( item => {

                        return (
                            (item.es_activo === '1' ? 

                            <Grid item xs={12} sm={4} onClick={ () => selectProyectoClick(item.id)}>
                                <Card className={ proyectoSeleccionado === item.id ? classes.cardProyectoSeleccionado : classes.cardProyecto }>
                                    <CardActionArea>
                                        {/* <CardMedia
                                        className={classes.cardMedia}
                                        image={`https://agrocrm.agrorecomenda.com/api/assets/img/proyectos/${item.id}.png`}
                                        title={item.nombre}

                                        /> */}
                                        <CardContent>
                                            <Grid container>
                                                <Grid item>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    {item.nombre}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    Publicidades activas en { monthName( mesSeleccionado ) }
                                                </Typography>
                                                </Grid>
                                                <Grid item className={classes.contendorMarcadosProyectos} >
                                                <Typography variant="body1" component="p" className={classes.marcadorProyectos} color="textSecondary">
                                                    {item.num_publicidades}
                                                </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>

                            :

                            <></>

                            )
                        )
                        
                    })
                }
            </Grid>
        )
    }
    const columnas = [
        { field: 'empresa_nombre', headerName: 'Empresa', minWidth: 250, flex: 1 },
        { field: 'titulo', headerName: 'Titulo', minWidth: 250, flex: 1 },
        {
            'field': 'fecha_desde', 
            'width':150, 
            'headerName': 'F. Desde', 
            'description': 'Fecha Desde',
            type: 'date',
            renderCell: (cellValues) => {
                const fechaPubli = new Date(cellValues.value)
                const str_fechaPubli = fechaPubli.getFullYear() + '-' + (fechaPubli.getMonth() + 1);
                const fechaHoy = new Date()
                const str_fechaHoy = yearSeleccionado + '-' + mesSeleccionado;

                return (
                    (cellValues.value !== null) ?
            
                        <>

                        {
                        ( str_fechaPubli === str_fechaHoy ? 
                        
                        <Moment format="DD/MM/YYYY" style={{color: PRIMARY_COLOR}}>
                            {cellValues.value}
                        </Moment>

                        :

                        <Moment format="DD/MM/YYYY">
                            {cellValues.value}
                        </Moment>

                        )
                        }
                        </>
                    :
            
                    <></>
            
                    
                );
            } 
        },
        {
            'field': 'fecha_hasta_indefinida', 
            'width':150, 
            'headerName': 'F. Hasta', 
            'description': 'Fecha Hasta',
            type: 'date',
            renderCell: (cellValues) => {
                const fechaPubli = new Date(cellValues.value)
                const str_fechaPubli = fechaPubli.getFullYear() + '-' + (fechaPubli.getMonth() + 1);
                const fechaHoy = new Date()
                const str_fechaHoy = yearSeleccionado + '-' + mesSeleccionado;

                return (
                    (cellValues.value !== null) ?
            
                        <>

                        {
                        ( str_fechaPubli === str_fechaHoy ? 
                        
                        <Moment format="DD/MM/YYYY" style={{color: ERROR_COLOR}}>
                            {cellValues.value}
                        </Moment>

                        :

                        <Moment format="DD/MM/YYYY">
                            {cellValues.value}
                        </Moment>

                        )
                        }
                        </>
                    :
            
                    <></>
            
                    
                );
            } 
        },
        { field: 'observaciones', headerName: 'Observaciones', minWidth: 250, flex: 1 },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Modificar"
                onClick={linkPublicidadDetalle(params.id)}
                showInMenu
              />,
            ],
        },
      ];

      /* eslint-disable react-hooks/exhaustive-deps */
  const linkPublicidadDetalle = React.useCallback(
    (id) => () => {
        console.log(id)
      //history("/maestros/entidades_comerciales/edit/" + id);
    },
    [],
  );

  const changeFecha = (op) => {
    if(op === '>') {
        if( mesSeleccionado === 12 ) {
            setMesSeleccionado(1);
            setYearSeleccionado(yearSeleccionado+1);
        } else {
            setMesSeleccionado(mesSeleccionado+1);
        }
    }
    if(op === '<') {
        if( mesSeleccionado === 1 ) {
            setMesSeleccionado(12);
            setYearSeleccionado(yearSeleccionado-1);
        } else {
            setMesSeleccionado(mesSeleccionado-1);
        }
    }
  }
    const Contenido = () => {
        return (

            proyectos.length > 0 ?

                <>
                    
                    <div style={{flexGrow: 1}}>
                    <Grid container spacing={3} style={{paddingTop: '20px'}}>
                        <Grid item>
                            <Typography gutterBottom variant="h6" component="h3">
                                { monthName( mesSeleccionado ) } {yearSeleccionado}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item><Chip label="<" onClick={() => changeFecha('<')} /></Grid>
                                <Grid item><Chip label=">" onClick={() => changeFecha('>')}/></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <BotonesSeleccionProyecto />

                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={12} style={{paddingTop: '50px'}}>

                            <Typography gutterBottom variant="h6" component="h3">

                                Publicidades activas

                            </Typography>
                            
                            <div style={{ height: 500, width: '100%' }}>
                                <DataGrid rows={publicidades} columns={columnas} />
                            </div>

                        </Grid>

                    </Grid>

                    </div>
                </>
                :
                <EmptyComponent />
        )
    }
    const EmptyComponent = () => {
        return (
            <Box className={classes.emptyContainer}>
                <img className={classes.emptyImage} src={noResult} alt="No se han encontrado resultados" />
                <h1 style={{ color: PRIMARY_COLOR }}>Todavía no tienes tareas creadas!!</h1>
            </Box>
        )
    }
    return (
        <div className={classes.containerMain}>
            {
                (loading ?
                    <LoadingComponent isFullScreen={true} />
                    :
                    <Contenido />
                )
            }
        </div>
    )
}
