import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'

// export async function insertTarea(descripcion, fecha, usuario_id, tarea_padre_id, dias, sufijoDias) {
//     try {
//         var config = {
//             headers: {
//                 'content-type': 'application/x-www-form-urlencoded'
//             }
//         };

//         var params = new URLSearchParams();
//         params.append('descripcion', descripcion);
//         params.append('fecha', fecha);
//         params.append('usuario_id', usuario_id);
//         params.append('tarea_padre_id', tarea_padre_id);
//         params.append('dias', dias);
//         params.append('sufijoDias', sufijoDias);

//         const response = await axios.post(BASE_URL_SERVICE + 'tarea/addtodo', params, config);

//         return response;
//     } catch (error) {
//         return undefined;
//         // throw error;
//     }

// }

// export async function checkTarea(tarea_id, is_checked) {
//     try {
//         var config = {
//             headers: {
//                 'content-type': 'application/x-www-form-urlencoded'
//             }
//         };

//         var params = new URLSearchParams();
//         params.append('tarea_id', tarea_id);
//         params.append('is_checked', is_checked);

//         const response = await axios.post(BASE_URL_SERVICE + 'tarea/checktodo', params, config);

//         return response;
//     } catch (error) {
//         return undefined;
//         // throw error;
//     }

// }


// export async function getProyectos({ usuario_id, proyecto_id = -1 }) {
//     try {
//         var config = {
//             headers: {
//                 'Access-Control-Allow-Origin': '*',
//                 'content-type': 'text/json'
//             }
//         };

//         const response = await axios.get(BASE_URL_SERVICE + 'proyecto', {
//             params: {
//                 usuario_id,
//                 fecha_desde,
//                 fecha_hasta,
//                 proyecto_id
//             }
//         }, config);

//         return response.data;
//     } catch (error) {
//         return undefined;
//         // throw error;
//     }

// }

export async function getProyectosActivos({ usuario_id,fecha_desde, fecha_hasta, }) {
    try {
        var config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'text/json'
            }
        };

        const response = await axios.get(BASE_URL_SERVICE + 'proyecto/proyectos_activos', {
            params: {
                usuario_id,
                fecha_desde,
                fecha_hasta
            }
        }, config);

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}