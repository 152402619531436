import React from 'react';

import { useSelector, useDispatch } from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
    Link,
} from 'react-router-dom';


import { PRIMARY_COLOR, PRIMARY_COLOR_HOVER, SECONDARY_COLOR, SECONDARY_COLOR_HOVER } from '../../constants/colores'

import NavbarTop from "./../NavbarTop";
import { Container } from '@material-ui/core';
// import ContentTitle from "./../ContentTitle";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabColor: {
        color: theme.palette.common.white,
        backgroundColor: SECONDARY_COLOR,
        '&:hover': {
            backgroundColor: SECONDARY_COLOR_HOVER,
        },
    },
}));

const TemplateDashboard = (props) => {
    const { pretitle, title, showAdd = false, showBack = false, tareaId = -1 } = props;
    const classes = useStyles();

    return (

        <>

            {/* <!-- navbar navigation component --> */}
            <NavbarTop
                title={title}
                showBack={showBack}
            />
            {/* <!-- end of navbar navigation --> */}

            <div className="wrapper" style={{ marginTop: 60 }}>

                <div id="body" >


                    <div className="content">
                        <Container maxWidth="lg">

                            {/* <ContentTitle pretitle={pretitle} title={title} /> */}

                            {props.children}

                        </Container>
                    </div>

                </div>
            </div>

            {showAdd &&
                <Fab aria-label="add" className={clsx(classes.fab, classes.fabColor)} component={Link} to={`/publicidad/add/${tareaId}`}>
                    <AddIcon />
                </Fab>
            }
        </>
    );
}

export default TemplateDashboard;