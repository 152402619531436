import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    loadingContainerFullScreen: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    }
}));

const LoadingComponent = ({ isFullScreen = false }) => {
    const classes = useStyles();

    return (

        (
            isFullScreen ?
                <Box className={classes.loadingContainerFullScreen} >
                    <CircularProgress />
                </Box >
                :
                <Box className={classes.loadingContainer}>
                    <CircularProgress />
                </Box >
        )

    )
}

export default LoadingComponent;