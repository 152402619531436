import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Grid } from '@material-ui/core'



import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';

import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import StarIcon from '@material-ui/icons/Star';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import avatar from '../../assets/img/avatar.png';
import logoBackgroundColor from '../../assets/img/logo.png';

import {
    selectUsuarioSeleccionado,
} from "../../redux/reducer/loginReducer";

import {
    Link,
} from 'react-router-dom';
import { CenterFocusStrong } from '@material-ui/icons';

import { PRIMARY_COLOR } from '../../constants/colores'
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appbar: {
        backgroundColor: PRIMARY_COLOR,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        textAlign: 'center',
        lineHeight: '50px'
    },
    avatar: {
        width: 250,
    },
    list: {
        width: 250,
        marginTop: 10
    },
    fullList: {
        width: 'auto',
    },
}));


const NavbarTop = (props) => {
    const { title = null, showBack } = props

    const classes = useStyles();

    // --------------------------------------------------------------
    // handleAbrirSidebar
    //
    // Permite abrir y cerrar la sidebar
    // --------------------------------------------------------------
    const dispatch = useDispatch();

    // --------------------------------------------------------------
    // Usuario seleccionado
    // --------------------------------------------------------------
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    const preventDefault = (event) => event.preventDefault();




    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const anchor = 'left';

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <ListItem button component={Link} to="/home" >
                <ListItemIcon><BarChartIcon /></ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button component={Link} to="/home" >
                <ListItemIcon><FileCopyIcon /></ListItemIcon>
                <ListItemText primary="Contratos" />
            </ListItem>
            <ListItem button component={Link} to="/home" >
                <ListItemIcon><PeopleIcon /></ListItemIcon>
                <ListItemText primary="Clientes" />
            </ListItem>
            <ListItem button component={Link} to="/home" >
                <ListItemIcon><FolderOpenIcon /></ListItemIcon>
                <ListItemText primary="Proyectos" />
            </ListItem>

            <Divider />
            <ListItem button component={Link} to="/home" >
                <ListItemIcon><PersonIcon /></ListItemIcon>
                <ListItemText primary="Perfil" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to="/logout" >
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary="Salir" />
            </ListItem>
        </div>
    );

    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    let history = useHistory();
    return (
        <>
            <AppBar position="fixed" className={classes.appbar}>
                <Toolbar>
                    <Grid justify={"space-between"} container>
                        <Grid xs={1} item>
                            {showBack ?
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => history.goBack()}>
                                    <ArrowBackIosIcon />
                                </IconButton>
                                :
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(anchor, true)}>
                                    <MenuIcon />
                                </IconButton>
                            }
                        </Grid>
                        <Grid xs={10} justifyContent='center' item>
                            <Typography variant="h6" className={classes.title}>
                                {title == null ? 'CRMAgro' : title}
                            </Typography>
                        </Grid>
                        <Grid xs={1} item></Grid>
                    </Grid>
                    {/* <Button color="inherit" component={Link} to="/logout" onClick={() => { }}>Salir</Button> */}
                </Toolbar>
            </AppBar>

            <React.Fragment key={anchor}>
                <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                >
                    <img src={avatar} className={classes.avatar} />
                    {list(anchor)}
                </SwipeableDrawer>
            </React.Fragment>
        </>
    );
}

export default NavbarTop;