import axios from "axios";
import { selectUsuarioSeleccionado } from "../reducer/loginReducer";

import getUsuario from '../../services/getUsuario'

const USERS_REQUEST = "USERS_REQUEST";
const USERS_SUCCESS = "USERS_SUCCESS";
const USERS_ERROR = "USERS_ERROR";
const USERS_LOGOUT = "USERS_LOGOUT";

function usersRequest() {
    return { type: USERS_REQUEST };
}
function usersSuccess(payload) {
    return { type: USERS_SUCCESS, payload };
}
function usersError(payload) {
    return { type: USERS_ERROR, payload };
}
function usersLogout() {
    return { type: USERS_LOGOUT };
}

const usersTypes = {
    USERS_REQUEST,
    USERS_ERROR,
    USERS_SUCCESS,
    USERS_LOGOUT
};

function loginUser(username, password) {
    return async function thunk(dispatch, getState) {
        const usuarioSeleccionado = selectUsuarioSeleccionado(getState());
        // console.log(usuarioSeleccionado);

        if (usuarioSeleccionado === null) {
            dispatch(usersRequest());
            const consulta_login = await getUsuario(username, password)

            if (consulta_login !== undefined) {
                const usuario = consulta_login.data.usuario;
                // if( (username==='fernando@emociom.com' && password ==='123456') || (username==='jvinolo@vyme.es' && password ==='123456') ) {
                try {

                    let data = {
                        username: usuario.mail,
                        password: '******',
                        nombre: usuario.nombre_completo,
                        id: usuario.id
                    };
                    // if (username==='fernando@emociom.com' && password ==='123456') {
                    //     data = {
                    //         username: 'fernando@emociom.com',
                    //         password: '******',
                    //         nombre: 'Fernando Perez'
                    //     };
                    // }
                    dispatch(usersSuccess(data));
                } catch (error) {
                    sessionStorage.clear();
                    dispatch(usersError(error));
                }

            } else {
                if (username !== "" && password !== "") {
                    dispatch(usersError("Usuario o password incorrectos"));
                }
            }
        }

    };
}

function logoutUser() {
    return async function thunk(dispatch, getState) {
        dispatch(usersLogout());

        console.log(getState());
    };
}
export {
    usersRequest,
    usersSuccess,
    usersError,
    loginUser,
    logoutUser,
    usersTypes
};

// function getPosts() {
//     return async function thunk(dispatch) {
//     dispatch(postsRequest());
//     try {
//         const { data } = await axios({
//         url: "https://jsonplaceholder.typicode.com/posts",
//         method: "GET"
//         });
//         dispatch(postsSuccess(data));
//     } catch (error) {
//         dispatch(postsError(error));
//     }
//     };
// }

// function getPostsByUser(userId) {
//   return async function thunk(dispatch, getState) {
//     dispatch(postsRequest());
//     try {
//       const users = selectUsers(getState());
//       const posts = selectPosts(getState());
//       const { data } = await axios({
//         url: `https://jsonplaceholder.typicode.com/posts?userId=${userId}`,
//         method: "GET"
//       });

//       const updatedPosts = [...posts, data];
//       let newSelectedUser;
//       const updatedUsers = users.map(user => {
//         if (user.id === userId) {
//           newSelectedUser = {
//             ...user,
//             postsKey: updatedPosts.length - 1
//           };
//           return newSelectedUser;
//         }
//         return user;
//       });

//       dispatch(usersSuccess(updatedUsers));
//       dispatch(postsSuccess(updatedPosts));
//       dispatch(usersSelectOne(newSelectedUser));
//     } catch (error) {
//       dispatch(postsError("Error loading posts"));
//     }
//   };
// }

